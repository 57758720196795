enum Tier {
  BASE = "Base Plan",
  STANDARD = "Standard Plan",
  PREMIUM = "Premium Plan",
}

export const webdev = {
  description:
    "Whether you’re looking to get a new site built or just a professional team to look after your site updates, we’ve got you covered. All of our new site builds are lightning fast, screen size responsive, fully SEO optimized, and most importantly, affordable.",
  plans: {
    basic: {
      price: 148,
      title: Tier.BASE,
      features: [
        "Up to 4 content pages",
        "Includes custom domain, email & email signature, SEO optimziation, and in house hosting",
        "Perfect for a Services Offered style website",
      ],
    },
    mid: {
      price: 268,
      title: Tier.STANDARD,
      features: [
        "Build includes 4 content pages, as well as products, cart, warranty + standard e-commerce pages",
        "Includes custom domain, email & email signature, SEO optimziation, and in house hosting",
        "Perfect for an e-commerce style website",
      ],
    },
    top: {
      price: 416,
      title: Tier.PREMIUM,
      features: [
        "Fully custom build: you dream it, we build it",
        "Perfect for clients looking for customizable and interactive sites with booking, event or sales integrations",
        "Includes custom domain, email & email signature, SEO optimziation, and in house hosting",
      ],
    },
    basicExisting: {
      price: 250,
      title: Tier.BASE,
      features: [
        "Up to 3 hours per month of site maintenance or content updates",
        "The ideal plan for regular home page, blog, or testimonial updates to keep your content up to date and boost SEO",
        "This plan is designed for sites needing monthly updates",
      ],
    },
    midExisting: {
      price: 500,
      title: Tier.STANDARD,
      features: [
        "Up to 8 hours per month of site maintenance or content updates",
        "The ideal plan if you’re looking for e-commerce updates such as new home page images, changing site banners, or rearranging product layouts",
        "Typically allows you to have weekly updates",
      ],
    },
    topExisting: {
      price: 1000,
      title: Tier.PREMIUM,
      features: [
        "16+ hours per month of site maintenance and content updates",
        "The ideal plan if you’re looking for a full service team to regularly help out with sales, promotions, and graphics",
      ],
    },
  },
}

export const digitalMarketing = {
  description:
    "Need some help getting your name out there or marketing your products & services? We specialize in Google Adwords, Email Marketing, as well as Search Engine Optimization.",
  plans: {
    adwords: {
      price: 350,
      title: "Google Adwords",
      features: [
        "Competitive ad spend credits",
        "Google ads experts optimize campaigns to maximize sales",
        "Increased visiblity for your brand",
      ],
    },
    email: {
      price: 160,
      title: "Email Marketing",
      features: [
        "Variable email plans available",
        "Email templates such as newsletters, blog updates, new sales, etc",
        "Optimize sales and customer engagement with strategic email marketing campaigns",
      ],
    },
    seo: {
      price: 250,
      isOneShotPricing: true,
      title: "SEO Optimization",
      features: [
        "Current website SEO optimization packages available",
        "Identify and fix potential backend issues",
        "Front end plans including strategic advertising, and content creation",
      ],
    },
  },
}

export const branding = {
  description:
    "If you’re struggling with the details of developing a brand, we’re here to help. We have professional designers and marketing professionals on deck to help with logo creation and brand development.",
  plans: {
    logo: {
      price: 468,
      isOneShotPricing: true,
      title: "Logo Design",
      features: [
        "Full logo development package",
        "Multiple logo options",
        "Professional designers",
        "Quick turn around",
      ],
    },
    dev: {
      price: 1189,
      isOneShotPricing: true,
      title: "Brand Development",
      features: [
        "Establish a brand identity including logos, colours, and company tone",
        "Create a brand that will build trust, retain customers, and set yourself apart from the competition",
      ],
    },
  },
}

export const social = {
  description:
    "Social media has become a key tool in any successful marketing plan, but it’s not always easy find and currate content. That’s where we step in, we happily offer social media strategy, content management, and content creation so you don’t have to worry about any of it.",
  plans: {
    basic: {
      price: 250,
      title: Tier.BASE,
      features: [
        "13-16 posts monthly (every 2nd day)",
        "Up to 7 photos or content graphics edited or curated in house per month",
        "Hashtag optimization",
        "Social strategy",
        "Photos/content provided by the client",
      ],
    },
    mid: {
      price: 380,
      title: Tier.STANDARD,
      features: [
        "28-31 post monthly (post every day)",
        "Up to 12 photos or content graphics edited or curated in house per month",
        "Hashtag optimization",
        "Social strategy",
        "Photos/content provided by the client",
      ],
    },
    top: {
      price: 850,
      title: Tier.PREMIUM,
      features: [
        "28-31 posts monthly (post every day)",
        "Up to 12 photos or content graphics edited or curated in house per month",
        "Hashtag optimization",
        "Social strategy",
        "All creative will be done by Who, including content photoshoots planned for every second month",
      ],
    },
    engagement: {
      price: 200,
      title: "Engagement",
      features: [
        "Active Instagram engagement",
        "Replies to comments posted on content (up to 20 per), daily liking of strategic engagement of relevant businesses and accounts",
      ],
    },
    shoot: {
      price: 500,
      isOneShotPricing: true,
      title: "Content Shoot",
      features: [
        "A social media content shoot including creative direction",
        "Will provide a minimum of 60 content photos",
      ],
    },
    consulting: {
      price: 500,
      isOneShotPricing: true,
      title: "Strategy/Consulting",
      features: [
        "A personalized strategy session either in office or over video call",
        "We will work together with you to create a unique social media strategy covering your tools, approach, and best practices",
        "A PDF document including what we discussed will be sent afterwards",
      ],
    },
  },
}
