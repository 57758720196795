import * as React from "react"
import styled, { keyframes } from "styled-components"
import { useState } from "react"
import upArrow from "../../icons/gui/upArrow.svg"
import { SubHeader } from "../shared/type"

interface Props {
  title: string
  expanded?: boolean
}

export const FullScreenPanel: React.FC<Props> = ({
  children,
  title,
  expanded,
}): JSX.Element => {
  const [open, setOpen] = useState(expanded)

  const toggle = () => {
    setOpen(!open)
  }

  const closedStyle = {
    backgroundColor: "var(--white)",
    transition: "max-height .5s ease-out",
    maxHeight: 0,
    height: "auto",
    overflow: "hidden",
  }

  const openedStyle = {
    backgroundColor: "var(--white)",
    transition: "max-height .7s ease-out",
    height: "auto",
    maxHeight: "777px",
    overflow: "hidden",
    display: "flex",
    alignItems: "space-between"
  }

  const ArrowIcon = styled.img`
    height: 50px;
    width: 50px;
    align-self: center;
    margin-left: 2rem;
    transition: .4s ease-out;
    transform: ${open? "rotateX(0deg)" : "rotateX(180deg)"};
  `

  return (
    <Container>
      <Head onClick={toggle}>
        <SubHeader style={{marginBottom: 0}}>{title}</SubHeader>
        <ArrowIcon src={upArrow} />
      </Head>
      <div style={open ? openedStyle : closedStyle} >
          <Content>{children}</Content>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: initial;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  height:100%;
  padding: var(--section-padding-desktop);
  padding-left: 50px;
  padding-right: 50px;
`

const Head = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey);
  padding: 1rem 0;
  align-items: center;
  cursor: pointer;
`