import * as React from "react"
import { Head } from "../../components/head"
import { Layout } from "../../components/layout"
import SEO from "../../components/seo"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { ExpansionPanel } from "../../components/_packages/expansionPanel"
import { ExpandedPanel } from "../../components/_packages/expandedPanel"
import { webdev } from "../../content/packageContent"
import BackgroundImage from "gatsby-background-image"
// import { AdditionalFeatures } from "../../components/_packages/AdditionalFeatures"
import {
  RelatedPackages,
  Package,
} from "../../components/_packages/relatedPackages"
import { FullScreenPanel } from "../../components/_packages/fullScreenPanel"
import { Header, HeaderCenter } from "../../components/shared/type"
import { SectionContainer } from "../../components/shared/containers"
import { breakPoints } from "../../components/shared/break-points"

const WebDesignAndDevelopmentPage = (): JSX.Element => {
  const { seoImg, webdImg, brandingImg, socialImg } = useStaticQuery(
    graphql`
      query {
        webdImg: file(relativePath: { eq: "services/webDev.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const webdFluid = webdImg.childImageSharp.fluid

  return (
    <>
      <SEO title="Web Design & Development" />
      <Head title={"Web Design & Development"} />
      <Layout>
        <SectionContainer style={{paddingBottom: "2rem"}}>
          <PackageHeader>
            <FluidImg fluid={webdFluid} style={{ backgroundSize: "contain" }} />
            <div>
              <Header>Web Design & Development</Header>
              <Description>{webdev.description}</Description>
            </div>
          </PackageHeader>
        </SectionContainer>
        
          <PackagesContainer>

          
          <SectionTitle>New Web Development</SectionTitle>
          <PanelsSmall>
            <ExpansionPanel
              plan={webdev.plans.basic}
              // expanded={true}
              package="Web Design & Development"
            />
            <ExpansionPanel
              plan={webdev.plans.mid}
              package="Web Design & Development"
            />
            <ExpansionPanel
              plan={webdev.plans.top}
              package="Web Design & Development"
            />
          </PanelsSmall>

          <SectionTitle>Web Maintenance & Updates</SectionTitle>
          <PanelsSmall>
            <ExpansionPanel
              plan={webdev.plans.basicExisting}
              package="Web Maintenance & Updates"
            />
            <ExpansionPanel
              plan={webdev.plans.midExisting}
              package="Web Maintenance & Updates"
            />
            <ExpansionPanel
              plan={webdev.plans.topExisting}
              package="Web Maintenance & Updates"
            />
          </PanelsSmall>
          <FullScreenPanel title="New Web Development" 
          // expanded={true}
          >
            <ExpandedPanel
              plan={webdev.plans.basic}
              package="Web Design & Development"
            />
            <ExpandedPanel
              plan={webdev.plans.mid}
              package="Web Design & Development"
            />
            <ExpandedPanel
              plan={webdev.plans.top}
              package="Web Design & Development"
            />
          </FullScreenPanel>
          <FullScreenPanel title="Web Maintenance & Updates">
            <ExpandedPanel
              plan={webdev.plans.basicExisting}
              package="Web Maintenance & Updates"
            />
            <ExpandedPanel
              plan={webdev.plans.midExisting}
              package="Web Maintenance & Updates"
            />
            <ExpandedPanel
              plan={webdev.plans.topExisting}
              package="Web Maintenance & Updates"
            />
          </FullScreenPanel>
          {/* <Features>
            <AdditionalFeatures />
          </Features> */}
          <RelatedPackages currentPackage={Package.WEBDEV} />
          </PackagesContainer>
   
      </Layout>
    </>
  )
}

const Description = styled.p`
  @media (min-width: 768px) {
  }
`

const Features = styled.div`
  background-color: var(--white);

  @media (min-width: 768px) {
    background-color: var(--grey);
    padding: 3rem 0;
  }
`

const FluidImg = styled(BackgroundImage)`
  height: 200px;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    height: 300px;
    width: 400px;
    justify-self: end;
  }
`

const PackagesContainer = styled.div`
  grid-column: 1/-1;
`

const PackageHeader = styled.div`
  @media (min-width: ${breakPoints.tablet}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-column-gap: 75px;
  }
`

const PanelsSmall = styled.div`
  :nth-of-type(3) {
    background-color: var(--grey);
  }

  :nth-of-type(3) > div {
    background-color: var(--grey);
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const SectionTitle = styled(HeaderCenter)`
  color: var(--brand-green);
  padding: 1.5rem 0;
  background-color: var(--grey);

  @media (min-width: 768px) {
    display: none;
  }
`

export default WebDesignAndDevelopmentPage
