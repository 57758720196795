import * as React from "react"
import styled from "styled-components"
import { Button } from "../../atoms/button"
import { Link } from "gatsby"
import { observer } from "mobx-react-lite"
import { UIStoreContext } from "../../stores/UIStore"

type Plan = {
  price: number
  isOneShotPricing?: boolean
  title: string
  features: string[]
}

interface Props {
  plan: Plan
  package: string
}

export const ExpandedPanel: React.FC<Props> = observer(props => {
  const { plan, package: service } = props
  const { price, title: planName, features, isOneShotPricing: oneShot } = plan

  const { packageSelected } = React.useContext(UIStoreContext)

  packageSelected.package = service

  const handleClick = () => {
    packageSelected.plan = planName
  }

  return (
    <Container>
      <Head>
        <HeadTitle>
          <Price>
            <span>*</span>${price}
            {oneShot ? "" : "/"}
          </Price>
          {oneShot ? "" : "mo"} <Tier>{planName}</Tier>
        </HeadTitle>
      </Head>
   
        <FeatureList>
          {features.map(feature => (
            <li key={feature}>
              <Tick>✓</Tick><span>{feature}</span>
            </li>
          ))}
        </FeatureList>
        <SmallText>
          <span>*</span>Prices starting from
        </SmallText>
        <Link to={`/get-started`}>
          <StartButton onClick={handleClick}>get started</StartButton>
        </Link>
    
    </Container>
  )
})

const Container = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 100%;
  }
`

const FeatureList = styled.ul`
  list-style: none;
  padding: 15px;
  margin-bottom: 1.5rem;
  height: 100%;

  @media (min-width: 768px) {
    padding-top: 2.5rem;
  }
  li {
    font: 1rem/1.5rem var(--body-font);
  }
`

const Head = styled.div`
  display: grid;
  align-items: center;

  grid-template-columns: 1fr 50px;
  > h1 {
    font: 1.3rem/1.7rem var(--bold);
    color: var(--white);
  }
  padding: 0.4rem 25px 0.4rem 50px;
  background-color: var(--brand-green);

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));

    padding: 1rem;
  }
`

const HeadTitle = styled.h1`
  padding-left: 1rem;
`

const Price = styled.span`
  > span {
    font: 1rem/1rem var(--ultra);
    vertical-align: super;
  }
  font: 1.5rem/1.5rem var(--bold);
`

const SmallText = styled.p`
  font: italic 0.8rem/0.8rem var(--body-font);
  > span {
    vertical-align: super;
  }
`

const StartButton = styled(Button)`
  padding: 0.9rem 5.5rem;
  @media (min-width: 768px) {
    padding: 0.9rem 0;
    width: 100%;
    margin-bottom: 3rem;
  }
`

const Tier = styled.span`
  font: 1rem/1.4rem var(--body-font);
  padding: 0 1rem;
`

const Tick = styled.span`
  color: var(--brand-green);
  font: bold 1rem/1rem var(--bold);
`
