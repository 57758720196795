import * as React from "react"
import styled from "styled-components"
import { useState } from "react"
import { Button } from "../../atoms/button"
import upArrow from "../../icons/gui/upArrow.svg"
import downArrow from "../../icons/gui/downArrow.svg"
import { Link } from "gatsby"
import { observer } from "mobx-react-lite"
import { UIStoreContext } from "../../stores/UIStore"

type Plan = {
  price: number
  title: string
  features: string[]
  isOneShot?: boolean
}

interface Props {
  plan: Plan
  package: string
  expanded?: boolean
}

export const ExpansionPanel: React.FC<Props> = observer(
  ({ expanded, plan, package: service }) => {
    const { packageSelected } = React.useContext(UIStoreContext)

    packageSelected.package = service

    const handleBtnClick = () => {
      packageSelected.plan = plan.title
    }

    const [open, setOpen] = useState(expanded)

    const toggle = () => {
      setOpen(!open)
    }

    const closedStyle = {
      backgroundColor: "var(--white)",
      transition: "max-height .6s ease-out",
      maxHeight: 0,
      height: "auto",
      overflow: "hidden",
    }
    
    const openedStyle = {
      backgroundColor: "var(--white)",
      transition: "max-height .7s ease-out",
      maxHeight: "777px",
      overflow: "hidden",
    }

    const { price, title: planName, features, isOneShot: oneShot } = plan

    const ArrowIcon = styled.div`
      background-image: url(${open ? upArrow : downArrow});
      background-size: contain;
      background-repeat: no-repeat;
      height: 50px;
      width: 50px;
      filter: invert(1);
      align-self: center;
    `

    return (
      <Container>
        <Head onClick={toggle}>
          <HeadTitle>
            <Price>
              <span>*</span>${price}
              {oneShot ? "" : "/"}
            </Price>
            {oneShot ? "" : "mo"} <Tier>{planName}</Tier>
          </HeadTitle>
          <ArrowIcon />
        </Head>
        <div style={open ? openedStyle : closedStyle}>
          <Content>
            <FeatureList>
              {features.map(feature => (
                <li key={feature}>
                  <Tick>✓</Tick> {feature}
                </li>
              ))}
            </FeatureList>
            <SmallText>
              <span>*</span>Prices starting from
            </SmallText>
            <Link to="/get-started">
              <StartButton onClick={handleBtnClick}>get started</StartButton>
            </Link>
          </Content>
        </div>
      </Container>
    )
  }
)

const Container = styled.div`
  padding-bottom: 3rem;
  :last-child {
    padding-bottom: 5rem;
  }
`

const Content = styled.div`
  li {
    font: 1rem/1.5rem var(--body-font);
  }
  padding: 0 3rem;
  margin-bottom: 2rem;
`

const FeatureList = styled.ul`
  list-style: none;
  padding: 1rem 0rem;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    padding-top: 2.5rem;
  }
`

const Head = styled.div`
  display: grid;
  align-items: center;
  cursor: pointer;

  grid-template-columns: 1fr 50px;
  > h1 {
    font: 1.3rem/1.7rem var(--bold);
    color: var(--white);
  }
  padding: 0rem 1rem 0rem 2rem;
  background-color: var(--brand-green);

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));

    padding: 1rem;
  }
`

const HeadTitle = styled.h1``

const Price = styled.span`
  font: 1.5rem/1.5rem var(--bold);
  > span {
    font: 1rem/1rem var(--ultra);
    vertical-align: super;
  }
`

const SmallText = styled.p`
  font: italic 0.8rem/0.8rem var(--body-font);
  > span {
    vertical-align: super;
  }
`

const StartButton = styled(Button)`
  padding: 0.9rem 5.5rem;
  width: 100%;

  @media (min-width: 768px) {
    padding: 1.4rem 6rem;
  }
`

const Tier = styled.span`
  font: 1rem/1.4rem var(--body-font);
  margin-left: 1rem;
`

const Tick = styled.span`
  color: var(--brand-green);
  font: bold 1rem/1rem var(--bold);
`
