import * as React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Button } from "../../atoms/button"
import { webdev } from "../../content/packageContent"
import { breakPoints } from "../shared/break-points"
import { SectionContainer } from "../shared/containers"
import { SubHeaderCenter, HeaderCenter } from "../shared/type"

interface Props {
  currentPackage: Package
}

export enum Package {
  WEBDEV,
  SEO,
  BRAND,
  SOCIAL,
}

export const RelatedPackages: React.FC<Props> = (props): JSX.Element => {
  const { seoImg, webdImg, brandingImg, socialImg } = useStaticQuery(
    graphql`
      query {
        webdImg: file(relativePath: { eq: "services/webDev.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        seoImg: file(relativePath: { eq: "services/marketingSEO.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brandingImg: file(relativePath: { eq: "services/branding.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        socialImg: file(relativePath: { eq: "services/socialMedia.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const webdFluid = webdImg.childImageSharp.fluid
  const seoFluid = seoImg.childImageSharp.fluid
  const brandingFluid = brandingImg.childImageSharp.fluid
  const socialFluid = socialImg.childImageSharp.fluid

  const { currentPackage } = props
  const showWeb = !(currentPackage === Package.WEBDEV)
  const showSEO = !(currentPackage === Package.SEO)
  const showBrand = !(currentPackage === Package.BRAND)
  const showSocial = !(currentPackage === Package.SOCIAL)

  return (
    <SectionContainer style={{borderTop: "3px solid var(--grey)"}}>
      <HeaderCenter>Other Services</HeaderCenter>
      <Content>
        {showWeb && (
          <FeatureCell>
            <GatsbyLink to={"/packages/" + "web-design-and-development"}>
              <Img fluid={webdFluid} imgStyle={{width:"100%", height: "auto"}}/>
              <SubHeaderCenter>
                Web Design &<SBR /> Development
              </SubHeaderCenter>
              <DesktopButton>view packages</DesktopButton>
            </GatsbyLink>
          </FeatureCell>
        )}

        {showSEO && (
          <FeatureCell>
            <GatsbyLink to={"/packages/" + "digital-marketing-and-seo"}>
              <Img fluid={seoFluid} imgStyle={{width:"100%", height: "auto"}}/>
              <SubHeaderCenter>Digital Marketing & SEO</SubHeaderCenter>
              <DesktopButton>view packages</DesktopButton>
            </GatsbyLink>
          </FeatureCell>
        )}

        {showBrand && (
          <FeatureCell>
            <GatsbyLink to={"/packages/" + "brand-development"}>
              <Img fluid={brandingFluid} imgStyle={{width:"100%", height: "auto"}}/>
              <BrandingHeader>Brand Development</BrandingHeader>
              <DesktopButton>view packages</DesktopButton>
            </GatsbyLink>
          </FeatureCell>
        )}

        {showSocial && (
          <FeatureCell>
            <GatsbyLink to={"/packages/" + "social-media-and-content-creation"}>
            <Img fluid={socialFluid} imgStyle={{width:"100%", height: "auto"}}/>
              <SubHeaderCenter>
                Social Media &<SBR /> Content Creation
              </SubHeaderCenter>
              <DesktopButton>view packages</DesktopButton>
            </GatsbyLink>
          </FeatureCell>
        )}
      </Content>
    </SectionContainer>
  )
}

const BrandingHeader = styled(SubHeaderCenter)`
  margin-top: -2.75rem;
  @media(min-width: ${breakPoints.tablet}) {
    margin-top: 0;
  }
`

const Content = styled.div`
    padding-top: 1rem;
  @media (min-width: 768px) {
    width: 100%;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
  }
  
`

const FeatureCell = styled.div`
width:100%;
  margin-bottom: 4rem;
  @media(min-width: ${breakPoints.tablet}) {
    margin-bottom: 0;
  }
`

const GatsbyLink = styled(Link)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

const DesktopButton = styled(Button)`
  display: none;
  @media (min-width: 768px) {
    display: initial;
    padding: 1.4rem 0;
    width: 100%;
  }
`
const SBR = styled.br`
  @media (min-width: 768px) {
    display: none;
  }
`