import styled from "styled-components"
import { breakPoints } from "./break-points"

export const BannerContainer = styled.section`
	grid-column: 1/-1;
  display: grid;
  grid-template-columns: var(--grid);
  padding: var(--banner-padding-mobile);
  @media (min-width: ${breakPoints.tablet}) {
		padding: var(--banner-padding-desktop);
  }
`

export const BannerContent = styled.div`
	grid-column: 2;
`

export const SectionContainer = styled.section`
	grid-column: 1/-1;
	display: grid;
	grid-template-columns: var(--grid);
	padding: var(--section-padding-mobile);
	@media (min-width: ${breakPoints.tablet}) {
		padding: var(--section-padding-desktop);
	}
	>* {
		grid-column:2;
	}
`

export const TextBubbleContainer = styled.div`
	background-color: var(--primary-transparent);
	padding: 1rem .75rem 1.25rem .75rem;
	border-radius: 8px;
	height: fit-content;
`

export const TextBubbleContent = styled.div`
	color: var(--white);
  font-size: 1rem;
`

export const SectionContent = styled.div`
	/* grid-column: 2; */
`